@tailwind utilities;
@tailwind base;
@tailwind components;

@layer base {
  @font-face {
    font-family: 'Lato-Bold';
    src: url('./fonts/Lato-Bold.ttf');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'Monsterract-Bold';
    src: url('./fonts/Montserrat/Montserrat-Bold.ttf');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'Monsterract-SemiBold';
    src: url('./fonts/Montserrat/Montserrat-SemiBold.ttf');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins/Poppins-Regular.ttf');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins-Medium';
    src: url('./fonts/Poppins/Poppins-Medium.ttf');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./fonts/Poppins/Poppins-SemiBold.ttf');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins-Bold';
    src: url('./fonts/Poppins/Poppins-Bold.ttf');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'Trashhand';
    src: url('./fonts/TrashHand.ttf');
    font-weight: 300;
    font-style: normal;
  }
}

@media only screen and (min-width: 768px) {
  .fixed-menu-col {
    position: fixed;
    width: 22.281%;
  }
  .fixed-header {
    position: fixed;
    top: 0;
    z-index: 99;
    padding-bottom: 8px;
    background: #f6f6f6;
  }
  body {
    padding-top: 76px;
    background: #f6f6f6;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .fixed-menu-col {
    width: 22%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .fixed-menu-col {
    width: 21%;
  }
  .fixed-header {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
}
