/* DateInput */

input.my-custom-input-class.z-30.relative{
  background: url("../assets/icons/chevronDown.svg") no-repeat 95% 50%;
}
.DatePicker__calendarContainer{
  transform: translate(7rem, -3.4rem) !important;
}

.ReactColorPicker-module_hex_value__10kKY{
	width: 70px !important;
}
.cm-paginator-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}
.cm-paginator-wrapper div {
  margin-right: 10px;
}
.page-status-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: relative;
  top: 50%;
  left: 45%;
  margin: 100px 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}